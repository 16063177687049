<template>
  <div>
    <a-modal v-model="visible" width="800px" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{ form.id ? "编辑" : "新增" }}</div>
      <div>
        <a-form-model ref="form" :model="form.others" :rules="rules" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item prop="meeting_name" label="会议名称">
            <a-input v-model="form.others.meeting_name" />
          </a-form-model-item>
          <a-form-model-item prop="host" label="主持人">
            <a-input v-model="form.others.host" />
          </a-form-model-item>
          <a-form-model-item prop="meeting_date" label="会议日期">
            <a-date-picker v-model="form.others.meeting_date" style="width: 100%" @change="chooseDate" />
          </a-form-model-item>
          <a-form-model-item prop="meeting_location" label="会议地点">
            <a-textarea v-model="form.others.meeting_location" />
          </a-form-model-item>
          <a-form-model-item prop="attendance" label="出席人员">
            <a-input v-model="form.others.attendance" />
          </a-form-model-item>
          <a-form-model-item prop="meeting_record" label="会议内容记录">
            <a-textarea v-model="form.others.meeting_record" />
          </a-form-model-item>
          <a-form-model-item prop="months_plan" label="下个月食品安全管理重点工作调度计划">
            <a-textarea v-model="form.others.months_plan" />
          </a-form-model-item>
          <a-form-model-item prop="reported_by" label="汇报人">
            <a-input v-model="form.others.reported_by" />
          </a-form-model-item>
          <a-form-model-item prop="head" label="负责人">
            <a-input v-model="form.others.head" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { monthControlAdd, monthControlEdit } from "@/api/control";

export default {
  name: "FormModal",
  props: ["visible", "form", "clientsClassificationOptions"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      levelOptions: [
        { id: "0", name: "0" },
        { id: "1", name: "1" },
        { id: "2", name: "2" },
        { id: "3", name: "3" },
      ],
      rules: {
        meeting_name: [
          { required: true, message: "请输入会议名称", trigger: "blur" }
        ],
        host: [
          { required: true, message: "请输入主持人", trigger: "blur" }
        ],
        meeting_date: [
          { required: true, message: "请选择会议日期", trigger: "change" }
        ]
      },
      loading: false,
    };
  },
  methods: {
    chooseDate(data, dateString) {
      this.form.others.meeting_date = dateString
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? monthControlEdit : monthControlAdd;
          func(this.form)
            .then(() => {
              this.$message.success(this.form.id ? "修改成功" : "新增成功");
              this.$emit(this.form.id ? "update" : "create");
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      if(!this.form.id) {
        this.$refs.form.resetFields();
      }
    },
  },
};
</script>

<style scoped></style>
